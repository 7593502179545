import { Model } from "@vuex-orm/core";
import Project from "./Project";

export default class Unit extends Model {
  static entity = "units";
  static url = "/unit"
  static friendlyName = "Unit"

  static fields() {
    return {
      id: this.number(0),
      name: this.attr(null),
      number: this.attr(null),
      lastChangedBy: this.attr(null),
      description: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      areaId: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    };
  }
}
