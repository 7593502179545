import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import database from "@/database";

// import createLogger from '../../../src/plugins/logger'
// let baseURL = "http://localhost:3000";

//Should be resolved as this is not right or prod environment
// let baseURL = "https://ved-api-dev.azurewebsites.net";

// if (process.env.NODE_ENV === "development") {
//   baseURL = "https://ved-api-dev.azurewebsites.net";
// } else if (process.env.NODE_ENV === "development_local") {
//   baseURL = "http://localhost:3000";
// }

VuexORM.use(VuexORMAxios, {
  axios,
  // baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
});
Vue.use(Vuex);

// Create Vuex Store and register database through Vuex ORM.
const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
});

export default store;
