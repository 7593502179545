import { Model } from "@vuex-orm/core";
import Project from "./Project";
import Medium from "./Medium";
import PipeSpec from "./PipeSpec";
import Line from "./Line";
import Equipment from "./Equipment";

export default class Service extends Model {
  static entity = "services";
  static url = "/service";
  static friendlyName = "Service";

  static state() {
    return {
      phases: ["Gas", "Liquid", "Solid", "Vapour"],
      classes: ["Process", "Utilities"],
      testPressureMethods: ["Hydro water test", "Service test", "According to PED 2014/68/EU"],
      inspectionOfWelds: [
        "N.A.",
        "Visual inspection",
        "Visual inspection external",
        "10% NDE",
        "20% X-ray",
        "100% X-ray",
        "20% endoscope",
      ],
      icon: "mdi-flask-outline",
    };
  }

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      phase: this.attr(null),
      pedPhase: this.attr(null),
      class: this.attr(null),
      minimumDesignTemperature: this.attr(null),
      maximumDesignTemperature: this.attr(null),
      minimumDesignPressure: this.attr(null),
      maximumDesignPressure: this.attr(null),
      pedFluidGroup: this.attr(null),
      // minimumOperatingTemperature: this.attr(null),
      // normalOperatingTemperature: this.attr(null),
      // maximumOperatingTemperature: this.attr(null),
      // minimumOperatingPressure: this.attr(null),
      // normalOperatingPressure: this.attr(null),
      // maximumOperatingPressure: this.attr(null),
      testPressure: this.attr(null),
      testPressureMethod: this.attr(null),
      maxVelocity: this.attr(null),
      molarWeight: this.attr(null),
      inspectionOfWelds: this.attr(null),
      weldClass: this.attr(null),
      paintSystem: this.attr(null),
      paintColor: this.attr(null),
      pipeCleaningRequirements: this.attr(null),
      isCipService: this.attr(null),
      isToxic: this.attr(null),
      isCorrosive: this.attr(null),
      isErosive: this.attr(null),
      lastChangedBy: this.attr(null),
      revision: this.attr(null),
      remarks: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      mediumId: this.attr(null),
      medium: this.belongsTo(Medium, "mediumId"),
      pipeSpecId: this.attr(null),
      pipeSpec: this.belongsTo(PipeSpec, "pipeSpecId"),
      cippedByServiceId: this.attr(null),
      cippedByService: this.belongsTo(Service, "cippedByServiceId"),
      // lines: this.hasMany(Line, "serviceId"),
      // equipment: this.hasMany(Equipment, "serviceId"),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      validFrom: this.attr(null),
    };
  }

  get tag() {
    return this.pipeSpec && this.medium ? `${this.pipeSpec.name}${this.medium.code}` : "";
  }
}
