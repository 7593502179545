import { Model } from "@vuex-orm/core";

export default class PipeSize extends Model {
  static entity = "pipeSizes";
  static url = '/pipesize'
  static friendlyName = "Pipe size"

  static fields() {
    return {
      id: this.attr(0),
      pipeSizeText: this.attr(null),
      size: this.attr(null),
      pipeSizeSystem: this.attr(null),
      outerDiameter: this.attr(null),
      convertedPipeSizeId: this.attr(null),
      convertedPipeSize: this.belongsTo(PipeSize, "convertedPipeSizeId"),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }
}