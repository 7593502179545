import { Model } from "@vuex-orm/core";
import Client from "./Client";
import Office from "./Office";
import TagConvention from "./TagConvention";
import TagPart from "./TagPart";
import SubProject from "./SubProject";
import Milestone from "./Milestone";

export default class Project extends Model {
  static entity = "projects";
  static url = "/project"
  static friendlyName = "Project"

  static state() {
    return {
      selectedProjectId: 0,
    };
  }

  static fields() {
    return {
      id: this.attr(0),
      clientId: this.attr(null),
      officeId: this.attr(null),
      name: this.attr(null),
      number: this.attr(null),
      pipeSizeSystem: this.attr(null),
      clientOrderNumber: this.attr(null),
      description: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      client: this.belongsTo(Client, "clientId"),
      office: this.belongsTo(Office, "officeId"),
      tagParts: this.belongsToMany(TagPart, TagConvention, "projectId", "tagPartId").as('tagConvention'),
      subProjects: this.hasMany(SubProject, "projectId"),
      milestones: this.hasMany(Milestone, "projectId"),
    };
  }

  get title() {
    return `${this.office?.projectSuffix || ""}${this.number?.toString().padStart(5, '0') || ""} | ${this.name || ""}`;
  }
}
