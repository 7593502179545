import { Model } from '@vuex-orm/core'
import Pid from './Pid'

export default class PidLine extends Model {
  static entity = 'pidLine'
  static url = "/pidline"

  static primaryKey = ['pidId', 'lineId']

  static fields () {
    return {
      pidId: this.attr(null),
      pid: this.belongsTo(Pid, "pidId"),
      lineId: this.attr(null),
    }
  }
}