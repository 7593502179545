import { Model } from '@vuex-orm/core'
import Project from "./Project";
import PipeSize from "./PipeSize";
import Schedule from "./Schedule";

export default class PipeSizeRangeSchedule extends Model {
  static entity = 'pipeSizeRangeSchedules'
  static url = "/pipesizerangeschedule"

  // static primaryKey = ['pipeSpecId', 'minPipeSizeId', 'maxPipeSizeId', 'scheduleId']

  static fields () {
    return {
      id: this.attr(0),
      manualWallThickness: this.attr(null),
      minPipeSizeId: this.attr(null),
      minPipeSize: this.belongsTo(PipeSize, "minPipeSizeId"),
      maxPipeSizeId: this.attr(null),
      maxPipeSize: this.belongsTo(PipeSize, "maxPipeSizeId"),
      scheduleId: this.attr(null),
      schedule: this.belongsTo(Schedule, "scheduleId"),
      pipeSpecId: this.attr(null),      
      lastChangedBy: this.attr(null),
      description: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    }
  }
}