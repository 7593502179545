import { Model } from "@vuex-orm/core";

export default class ApplicableDirective extends Model {
  static entity = "applicableDirectives";
  static url = "/applicableDirective"
  static friendlyName = "Applicable directive"

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      code: this.attr(null),
      lastChangedBy: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }
}
