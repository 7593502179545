import { Model } from '@vuex-orm/core'
import Project from "./Project";

export default class Pid extends Model {
  static entity = 'pids'
  static url = "/pid"
  static friendlyName = "Pid"

  static fields () {
    return {
      id: this.attr(0),
      title: this.attr(null),
      number: this.attr(0),
      lastChangedBy: this.attr(null),
      filePath: this.attr(null),
      revision: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    }
  }
}