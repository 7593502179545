import { Model } from "@vuex-orm/core";
import Project from "./Project";
import Area from "./Area";

export default class Plant extends Model {
  static entity = "plants";
  static url = "/plant"
  static friendlyName = "Plant"

  static fields() {
    return {
      id: this.number(0),
      name: this.attr(null),
      lastChangedBy: this.attr(null),
      description: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      areas: this.hasMany(Area, "plantId"),
    };
  }

  get treeViewChildren() {
    const children = this.areas
      .map((area) => area.treeViewChildren)
      .sort((a, b) => a.number - b.number);
    return { ...this, key: `${this.name}${this.description}`, children };
  }
}
