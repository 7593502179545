import { Model } from '@vuex-orm/core'

export default class InstrumentDatasheet extends Model {
  static entity = 'instrumentDatasheets'
  static url = "/instrumentDatasheet"
  static friendlyName = "Instrument datasheet"

  static fields () {
    return {
      id: this.attr(0),
      title: this.attr(null),
      number: this.attr(0),
      lastChangedBy: this.attr(null),
      filePath: this.attr(null),
      revision: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    }
  }
}