import { Model } from "@vuex-orm/core";
import pedCalculation from "../helpers/pedCalculation";
import ApplicableDirective from "./ApplicableDirective";
import Insulation from "./Insulation";
import Isometric from "./Isometric";
import Pid from "./Pid";
import PidLine from "./PidLine";
import PipeSize from "./PipeSize";
import PipeWallThickness from "./PipeWallThickness";
import Project from "./Project";
import Service from "./Service";
import SubProject from "./SubProject";
import TagConvention from "./TagConvention";
import Tracing from "./Tracing";
import Unit from "./Unit";

export default class Line extends Model {
  static entity = "lines";
  static url = "/line";
  static friendlyName = "Line";

  static state() {
    return {
      kvis: ["Y", "N", "N.A."],
      icon: "mdi-pipe",
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      number: this.attr(null),
      tagPrefix: this.attr(null),
      tagSuffix: this.attr(null),
      vicomaTag: this.attr(null),
      historyTag: this.attr(null),
      fromLine: this.attr(null),
      toLine: this.attr(null),
      minimumOperatingTemperature: this.attr(null),
      normalOperatingTemperature: this.attr(null),
      maximumOperatingTemperature: this.attr(null),
      minimumOperatingPressure: this.attr(null),
      normalOperatingPressure: this.attr(null),
      maximumOperatingPressure: this.attr(null),
      remarks: this.attr(null),
      notes: this.attr(null),
      pedRemarks: this.attr(null),
      pedRegistrationNumber: this.attr(null),
      tracingLocation: this.attr(null),
      tracingHoldingTemperature: this.attr(null),
      vapourPressure: this.attr(null),
      atmBoilingPoint: this.attr(null),
      minimumDensity: this.attr(null),
      normalDensity: this.attr(null),
      maximumDensity: this.attr(null),
      minimumViscosity: this.attr(null),
      normalViscosity: this.attr(null),
      maximumViscosity: this.attr(null),
      minimumMassFlow: this.attr(null),
      normalMassFlow: this.attr(null),
      maximumMassFlow: this.attr(null),
      minimumVelocity: this.attr(null),
      normalVelocity: this.attr(null),
      maximumVelocity: this.attr(null),
      insulationLocation: this.attr(null),
      insulationThickness: this.attr(null),
      kvi: this.attr(null),
      isometricDocumentName: this.attr(null),
      isometricClientDocumentName: this.attr(null),
      isometricNonDestructiveTesting: this.attr(null),
      isometricRegistrationNumber: this.attr(null),
      isometricXray: this.attr(null),
      isometricHeatTreatment: this.attr(null),
      isometricCoating: this.attr(null),
      isometricRemarksA: this.attr(null),
      isometricRemarksB: this.attr(null),
      isometricRemarksC: this.attr(null),
      isometricDocumentRevision: this.attr(null),
      isometricLayout: this.attr(null),
      isometricPipeSupportDwg: this.attr(null),
      isometricRevision0: this.attr(null),
      isometricRevision1: this.attr(null),
      isometricRevision2: this.attr(null),
      isometricRevision3: this.attr(null),
      isometricDescription0: this.attr(null),
      isometricDescription1: this.attr(null),
      isometricDescription2: this.attr(null),
      isometricDescription3: this.attr(null),
      isometricDate0: this.attr(null),
      isometricDate1: this.attr(null),
      isometricDate2: this.attr(null),
      isometricDate3: this.attr(null),
      isometricDrawn0: this.attr(null),
      isometricDrawn1: this.attr(null),
      isometricDrawn2: this.attr(null),
      isometricDrawn3: this.attr(null),
      isometricCheck0: this.attr(null),
      isometricCheck1: this.attr(null),
      isometricCheck2: this.attr(null),
      isometricCheck3: this.attr(null),
      isometricApproved0: this.attr(null),
      isometricApproved1: this.attr(null),
      isometricApproved2: this.attr(null),
      isometricApproved3: this.attr(null),
      lastChangedBy: this.attr(null),
      revision: this.attr(null),
      attributes: this.attr(null),
      state: this.attr(null),
      projectScope: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      applicableDirectiveId: this.attr(null),
      insulationId: this.attr(null),
      isometricId: this.attr(null),
      unitId: this.attr(null),
      serviceId: this.attr(null),
      pipeSizeId: this.attr(null),
      tracingId: this.attr(null),
      projectId: this.attr(null),
      pids: this.belongsToMany(Pid, PidLine, "lineId", "pidId"),
      insulation: this.belongsTo(Insulation, "insulationId"),
      applicableDirective: this.belongsTo(ApplicableDirective, "applicableDirectiveId"),
      isometric: this.belongsTo(Isometric, "isometricId"),
      unit: this.belongsTo(Unit, "unitId"),
      service: this.belongsTo(Service, "serviceId"),
      pipeSize: this.belongsTo(PipeSize, "pipeSizeId"),
      tracing: this.belongsTo(Tracing, "tracingId"),
      project: this.belongsTo(Project, "projectId"),
      subProjectId: this.attr(null),
      subProject: this.belongsTo(SubProject, "subProjectId"),
      validFrom: this.attr(null),
    };
  }

  get tag() {
    let tag = "";
    let tagParts = [];
    let tagPart;
    if (this.project) {
      tagParts = this.project.tagParts;
    } else {
      tagParts = Project.query().whereId(this.projectId).withAll().first()?.tagParts;
    }
    if (tagParts) {
      tagParts
      .filter((i) => i.objectType === "lineTagFormat")
      .sort((a, b) => a.tagConvention.index - b.tagConvention.index)
      .map((tagFormatPart) => {
        tagPart =
          tagFormatPart.modelName === Line.entity.slice(0, -1)
            ? this[tagFormatPart.propertyPath]
            : this.resolvePath(this, tagFormatPart.propertyPath);

        if (tagPart !== null && tagPart !== undefined) {
          tagPart = tagPart.toString();
          if (tagFormatPart.tagConvention.padding) {
            if (tagFormatPart.tagConvention.padding > 0) {
              tagPart = tagPart.padStart(tagFormatPart.tagConvention.padding, "0");
            } else {
              tagPart = tagPart.slice(0, tagFormatPart.tagConvention.padding);
            }
          }
        } else {
          tagPart = "";
        }

        tag = tag.concat(
          tagPart,
          tagFormatPart.tagConvention.delimiter !== null
            ? tagFormatPart.tagConvention.delimiter
            : ""
        );
      });
      tag = tag.replaceAll("--", "").replace(/\-$/, "");

      if (tag.startsWith("-")) {
        tag = tag.slice(1);
      }
  
      return tag;
    }
  }
  get pipeSpec() {
    return this.service.pipeSpec;
  }
  get medium() {
    return this.service.medium;
  }
  get pipeSizeRangeSchedule() {
    if (this.pipeSize && this?.service?.pipeSpec?.pipeSizeRangeSchedules) {
      return this.service.pipeSpec.pipeSizeRangeSchedules.find(
        (pipeSizeRangeSchedule) =>
          this.pipeSize.size >= pipeSizeRangeSchedule.minPipeSize.size &&
          this.pipeSize.size <= pipeSizeRangeSchedule.maxPipeSize.size
      );
    } else {
      return null;
    }
  }
  get schedule() {
    return this.pipeSizeRangeSchedule?.schedule;
  }
  get thickness() {
    return (
      this.pipeSizeRangeSchedule?.manualWallThickness ||
      (this.schedule
        ? PipeWallThickness.query()
            .where("scheduleId", this.schedule.id)
            .where("pipeSizeId", this.pipeSizeId)
            .withAll()
            .first()?.thickness
        : undefined)
    );
  }
  get pedCategory() {
    if (
      this.pipeSize &&
      this.service &&
      this.service.maximumDesignPressure !== null &&
      this.connectionSize !== null &&
      this.service.pedPhase !== null &&
      this.service.pedFluidGroup !== null
    ) {
      const maxDesignPressure = this.service.maximumDesignPressure;
      const pedPhase = this.service.pedPhase;
      const pedFluidGroup = this.service.pedFluidGroup;
      const diameterDN =
        this.pipeSize.pipeSizeSystem == 2
          ? this.pipeSize.size
          : PipeSize.query().where("id", this.pipeSize.convertedPipeSizeId).withAll().first()?.size;
      if (diameterDN !== undefined) {
        return pedCalculation.calculateLinePed(
          maxDesignPressure,
          diameterDN,
          pedPhase,
          pedFluidGroup
        );
      } else {
        return null;
      }
    }
    return null;
  }

  get actualVelocity() {
    if (
      this.thickness !== null &&
      this.thickness !== undefined &&
      this.normalMassFlow !== 0 &&
      this.normalDensity !== null &&
      this.pipeSize !== null
    ) {
      const wallThickness = this.thickness;
      const innerRadius = 0.5 * this.pipeSize.outerDiameter - wallThickness;
      const volumetricFlow = this.normalMassFlow / this.normalDensity;
      const actualVelocity = volumetricFlow / 3600 / (Math.PI * Math.pow(innerRadius * 0.001, 2));

      return actualVelocity;
    } else {
      return null;
    }
  }

  resolvePath = (object, path, defaultValue) =>
    path
      .split(/[\.\[\]\'\"]/)
      .filter((p) => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  exampleTag(lineTagConvention) {
    let tag = "";
    let tagPart;

    if (lineTagConvention === null) {
      return "";
    }

    if (lineTagConvention.length > 0) {
      lineTagConvention.forEach((tagFormatPart, index) => {
        tagPart =
          tagFormatPart.tagPart.modelName === Line.entity.slice(0, -1)
            ? this[tagFormatPart.tagPart.propertyPath]
            : this.resolvePath(this, tagFormatPart.tagPart.propertyPath);

        if (tagPart !== null && tagPart !== undefined) {
          tagPart = tagPart.toString();
          if (tagFormatPart.padding) {
            if (tagFormatPart.padding > 0) {
              tagPart = tagPart.padStart(tagFormatPart.padding, "0");
            } else {
              tagPart = tagPart.slice(0, tagFormatPart.padding);
            }
          }
          tag = tag.concat(
            tagPart,
            tagFormatPart.delimiter !== null ? tagFormatPart.delimiter : ""
          );
        }
      });

      return tag.replaceAll("--", "").replace(/\-$/, "");
    } else {
      console.log(lineTagConvention);
      return "";
    }
  }
}
