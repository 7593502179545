import { Model } from "@vuex-orm/core";
import Project from "./Project";

export default class Isometric extends Model {
  static entity = "isometrics";
  static url = "/isometric"
  static friendlyName = "Isometric"

  static fields() {
    return {
      id:  this.attr(0),
      number: this.attr(null),
      title: this.attr(null),
      filePath: this.attr(null),
      revision: this.attr(null),
      statusRevision: this.attr(null),
      statusFase: this.attr(null),
      statusDate: this.attr(null),
      statusIni: this.attr(null),
      revision0: this.attr(null),
      description0: this.attr(null),
      date0: this.attr(null),
      drawn0: this.attr(null),
      check0: this.attr(null),
      approved0: this.attr(null),
      revision1: this.attr(null),
      description1: this.attr(null),
      date1: this.attr(null),
      drawn1: this.attr(null),
      check1: this.attr(null),
      approved1: this.attr(null),
      revision2: this.attr(null),
      description2: this.attr(null),
      date2: this.attr(null),
      drawn2: this.attr(null),
      check2: this.attr(null),
      approved2: this.attr(null),
      revision3: this.attr(null),
      description3: this.attr(null),
      date3: this.attr(null),
      drawn3: this.attr(null),
      check3: this.attr(null),
      approved3: this.attr(null),
      layout: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    };
  }
}
