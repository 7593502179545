import { Model } from "@vuex-orm/core";
import Project from "./Project";
import Unit from "./Unit";

export default class Area extends Model {
  static entity = "areas";
  static url = "/area"
  static friendlyName = "Area"

  static fields() {
    return {
      id: this.number(0),
      name: this.attr(null),
      number: this.attr(null),
      lastChangedBy: this.attr(null),
      description: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      plantId: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      units: this.hasMany(Unit, "areaId"),
    };
  }

  get treeViewChildren() {    
    return { ...this, key: `${this.name}${this.number}${this.id}`, ...{ children : this.units.map((unit) => ({ key: `${unit.name}${unit.number}${unit.id}`, ...unit })).sort((a, b) => a.number - b.number) } };
  }
}
