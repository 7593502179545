import { Model } from "@vuex-orm/core";

export default class Office extends Model {
  static entity = "offices";
  static url = "/office";
  static friendlyName = "Office"

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      code: this.attr(null),
      projectSuffix: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }
}
