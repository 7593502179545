import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css' 

Vue.use(Vuetify);

const vuetifyOpts = {
  rtl: false,
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      dark: {
        primary: '#0b2d41',
        accent: '#57A098',
        secondary: '#5D7884',
        success: '#57A098',
        info: '#2196F3',
        warning: '#9A4704',
        error: '#FF5252',
        lightbackground: '#000000',
        darkbackground: '#A9AB9E',
        tablecolumnmenu: '#1E1E1E',
        vicblue: '#009bff',
        vicyellow: '#D2B468'
      },
      light: {
        primary: '#16577C',
        accent: '#57A098',
        secondary: '#5D7884',
        success: '#57A098',
        info: '#2196F3',
        warning: '#9A4704',
        error: '#FF5252',
        lightbackground: '#EDEDE1',
        darkbackground: '#A9AB9E',
        tablecolumnmenu: 'rgba(0, 0, 0, 0.87)',
        vicblue: '#009bff',
        vicyellow: '#D2B468'    
      }
    }
  }
}



export default new Vuetify(vuetifyOpts);
