import { Model } from "@vuex-orm/core";
import Project from "./Project";

export default class SubProject extends Model {
  static entity = "subProjects";
  static url = "/subProject";
  static friendlyName = "Sub project"

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      number: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    };
  }
}
