import { Model } from '@vuex-orm/core'

export default class Schedule extends Model {
  static entity = 'schedules'
  static url = '/schedule'
  static friendlyName = "Schedule"

  static fields () {
    return {
      id: this.attr(0),
      name: this.attr(null),
    }
  }
}