import { Model } from "@vuex-orm/core";

export default class TableState extends Model {
  static entity = "tableStates";
  static url = "/tablestate";
  static friendlyName = "Table state";
  static primaryKey = "routeName";

  static state() {
    return {};
  }

  static fields() {
    return {
      routeName: this.attr(null),
      userId: this.attr(null),
      columnState: this.attr(null),
      columnGroupState: this.attr(null),
      filterModel: this.attr(null),
      tabName: this.attr("General"),
    };
  }
}
