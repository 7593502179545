import { Model } from "@vuex-orm/core";
import Project from "./Project";
import PipeSizeRangeSchedule from "./PipeSizeRangeSchedule";
import Service from "./Service";

export default class PipeSpec extends Model {
  static entity = "pipeSpecs";
  static url = '/pipeSpec'
  static friendlyName = "Pipe spec"

  static state() {
    return {
      icon: "mdi-flask-outline"
    };
  }

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      material: this.attr(null),
      pipeStandard: this.attr(null),
      pressureRating: this.attr(null),
      temperatureRating: this.attr(null),
      revision: this.attr(null),
      lastChangedBy:this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      pipeSizeRangeSchedules: this.hasMany(PipeSizeRangeSchedule, "pipeSpecId"),
      services: this.hasMany(Service, "pipeSpecId"),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      validFrom: this.attr(null)
    };
  }

  
  get tag() {
    return `${this.name}`;
  }
}
