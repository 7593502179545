import { Model } from '@vuex-orm/core'
import Project from "./Project";
import Equipment from './Equipment';
import ApplicableDirective from './ApplicableDirective';

export default class EquipmentType extends Model {
  static entity = 'equipmentTypes'
  static url = "/equipmenttype"
  static friendlyName = "Equipment type"

  static fields () {
    return {
      id: this.attr(0),
      tag: this.attr(null),
      name: this.attr(null),
      category: this.attr(null),
      staticOrRotating: this.attr(null),
      attributes: this.attr(null),
      equipments: this.hasMany(Equipment, "equipmentTypeId"),
      applicableDirectiveId: this.attr(null),
      applicableDirective: this.belongsTo(ApplicableDirective, "applicableDirectiveId"),    
      lastChangedBy: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),      
    }
  }
}