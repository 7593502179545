import { Model } from "@vuex-orm/core";

export default class General extends Model {
  static entity = "generals";
  static url = "/general";
  static appVersion = process.env.PACKAGE_VERSION || "0";
  static buildNumber = process.env.VERSION || "0";
  static VED_BASE = process?.env.VED_BASE || "http://localhost:3000/api";
  static VED_SCOPES = process?.env.VED_SCOPES || "https://ved-api-dev.azurewebsites.net/user_impersonation";
  // static VED_WEBSOCKET_URL = process?.env.VED_WEBSOCKET_URL || "ws://localhost:3001";
  static VED_WEBSOCKET_URL = process?.env.VED_WEBSOCKET_URL || "wss://ws-ved-dev.azurewebsites.net";

  // static VED_BASE = process.env?.VED_BASE || 'http://localhost:3000/api'
  // static VED_SCOPES = process.env?.VED_SCOPES || "https://ved-api-dev.azurewebsites.net/user_impersonation";

  static state() {
    return {
      // VED_BASE: process.env?.VED_BASE || 'http://localhost:3000/api',
      // VED_SCOPES: process.env?.VED_SCOPES || "https://ved-api-dev.azurewebsites.net/user_impersonation",
      // VED_WEBSOCKET_URL: process.env?.VED_WEBSOCKET_URL || "http://localhost:3000",
    };
  }

  static fields() {
    return {
      id: this.attr(0),
      appBuildNumber: this.attr(null),
      isConnected: this.boolean(false),
    };
  }
}
