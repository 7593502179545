import { Model } from "@vuex-orm/core";
import Project from "./Project";

export default class Insulation extends Model {
  static entity = "insulations";
  static url = "/insulation"
  static friendlyName = "Insulation"

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      code: this.attr(null),
      prefix: this.attr(null),
      suffix: this.attr(null),
      revision: this.attr(null),
      lastChangedBy: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    };
  }
}