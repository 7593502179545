import { Model } from '@vuex-orm/core'
import Project from "./Project";

export default class ApplicationSettings extends Model {
  static entity = 'applicationSettings'
  static url = '/applicationSetting'
  static friendlyName = "Application settings"

  static fields () {
    return {
      id: this.attr(0),
      name: this.attr(null),
      value: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),      
    }
  }
}