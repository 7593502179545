import { Model } from "@vuex-orm/core";
import TableState from "./TableState";

export default class User extends Model {
  static entity = "users";
  static url = "/user";
  static friendlyName = "User"
  // static primaryKey = "username";  

  static state() {
    return {
      user: {
        environment: null,
        homeAccountId: null,
        idTokenClaims: null,
        localAccountId: null,
        name: null,
        tenantId: null,
        username: null,
        graphPhoto: null,
        graphDetails: null,
      },
    };
  }


  static fields() {
    return {
      id: this.attr(null),
      environment: this.attr(null),
      homeAccountId: this.attr(null),
      name: this.attr(null),
      username: this.attr(null),
      graphPhoto: this.attr(null),
      graphDetails: this.attr(null),
      jobTitle: this.attr(null),
      online: this.attr(false),
      columnStates: this.hasMany(TableState, "userId"),
    };
  }
}
