import { Model } from "@vuex-orm/core";
import pedCalculation from "../helpers/pedCalculation";
import ApplicableDirective from "./ApplicableDirective";
import Equipment from "./Equipment";
import InstrumentType from "./InstrumentType";
import Isometric from "./Isometric";
import Line from "./Line";
import Pid from "./Pid";
import PipeSize from "./PipeSize";
import PressureRating from "./PressureRating";
import Project from "./Project";
import SubProject from "./SubProject";
import TagConvention from "./TagConvention";
import Unit from "./Unit";
import ValveSpec from "./ValveSpec";

export default class Instrument extends Model {
  static entity = "instruments";
  static url = "/instrument";
  static friendlyName = "Instrument";

  static state() {
    return {
      icon: "mdi-gauge",
      kvis: ["Y", "N", "N.A."],
      connectionTypes: ["RF", "FF", "RJ", "BW/SW", "THDM/THDF", "G", "WF", "PL"],
      operatorTypes: ["HW", "L", "PA", "MA", "S", "GO"],
      bores: ["FB", "RB"],
      specialRequirements: ["FS", "AS", "MS", "SS", "FT"],
      silLevels: ["SIL 1", "SIL 2", "SIL 3", "SIL 4"]

    };
  }

  static fields() {
    return {
      id: this.attr(null),
      number: this.attr(null),
      tagPrefix: this.attr(null),
      tagSuffix: this.attr(null),
      vicomaTag: this.attr(null),
      componentType: this.attr(null),
      manufacturer: this.attr(null),
      model: this.attr(null),
      designCode: this.attr(null),
      connectionType: this.attr(null),
      operatorType: this.attr(null),
      areaClassification: this.attr(null),
      silClassification: this.attr(null),
      minimumOperatingTemperature: this.attr(null),
      normalOperatingTemperature: this.attr(null),
      maximumOperatingTemperature: this.attr(null),
      minimumOperatingPressure: this.attr(null),
      normalOperatingPressure: this.attr(null),
      maximumOperatingPressure: this.attr(null),
      minimumOutletPressure: this.attr(null),
      normalOutletPressure: this.attr(null),
      maximumOutletPressure: this.attr(null),
      minimumDensity: this.attr(null),
      normalDensity: this.attr(null),
      maximumDensity: this.attr(null),
      minimumVelocity: this.attr(null),
      normalVelocity: this.attr(null),
      maximumVelocity: this.attr(null),
      minimumViscosity: this.attr(null),
      normalViscosity: this.attr(null),
      maximumViscosity: this.attr(null),
      minimumMassFlow: this.attr(null),
      normalMassFlow: this.attr(null),
      maximumMassFlow: this.attr(null),
      materialBody: this.attr(null),
      materialSeat: this.attr(null),
      rating: this.attr(null),
      bore: this.attr(null),
      remarks: this.attr(null),
      notes: this.attr(null),
      state: this.attr(null),
      atexFlammableMedium: this.attr(null),
      atexAreaClassification: this.attr(null),
      atexProductGroup: this.attr(null),
      atexProductCategory: this.attr(null),
      atexProtectionType: this.attr(null),
      atexExplosionGroup: this.attr(null),
      atexTemperatureClass: this.attr(null),
      atexEquipmentProtectionLevel: this.attr(null),
      erosionCorrosionProof: this.attr(null),
      ioType: this.attr(null),
      calibratedRangeMinimum: this.attr(null),
      calibratedRangeMaximum: this.attr(null),
      loopNumber: this.attr(null),
      loopName: this.attr(null),
      loopSequence: this.attr(null),
      kvi: this.attr(null),
      specialRequirements: this.attr(null),
      secondaryService: this.boolean(false),
      lastChangedBy: this.attr(null),
      revision: this.attr(null),
      attributes: this.attr(null),
      state: this.attr(null),
      projectScope: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      applicableDirectiveId: this.attr(null),
      unitId: this.attr(null),
      lineId: this.attr(null),
      equipmentId: this.attr(null),
      isometricId: this.attr(null),
      pidId: this.attr(null),
      applicableDirective: this.belongsTo(ApplicableDirective, "applicableDirectiveId"),
      instrumentSizeId: this.attr(null),
      valveSpecId: this.attr(null),
      connectionSizeId: this.attr(null),
      projectId: this.attr(null),
      instrumentTypeId: this.attr(null),
      isometric: this.belongsTo(Isometric, "isometricId"),
      pid: this.belongsTo(Pid, "pidId"),
      pressureRatingId: this.attr(null),
      pressureRating: this.belongsTo(PressureRating, "pressureRatingId"),
      unit: this.belongsTo(Unit, "unitId"),
      line: this.belongsTo(Line, "lineId"),
      equipment: this.belongsTo(Equipment, "equipmentId"),
      instrumentType: this.belongsTo(InstrumentType, "instrumentTypeId"),
      instrumentSize: this.belongsTo(PipeSize, "instrumentSizeId"),
      valveSpec: this.belongsTo(ValveSpec, "valveSpecId"),
      connectionSize: this.belongsTo(PipeSize, "connectionSizeId"),
      project: this.belongsTo(Project, "projectId"),
      subProjectId: this.attr(null),
      subProject: this.belongsTo(SubProject, "subProjectId"),
      validFrom: this.attr(null),
    };
  }

  get tag() {
    let tag = "";
    let tagParts = [];
    let tagPart;
    if (this.project) {
      tagParts = this.project.tagParts;
    } else {
      tagParts = Project.query().whereId(this.projectId).withAll().first()?.tagParts;
    }
    if (tagParts) {
      tagParts
      .filter((i) => i.objectType === "instrumentTagFormat")
      .sort((a, b) => a.tagConvention.index - b.tagConvention.index)
      .map((tagFormatPart) => {
        tagPart =
          tagFormatPart.modelName === Instrument.entity.slice(0, -1)
            ? this[tagFormatPart.propertyPath]
            : this.resolvePath(this, tagFormatPart.propertyPath);

        if (tagPart !== null && tagPart !== undefined) {
          tagPart = tagPart.toString();
          if (tagFormatPart.tagConvention.padding) {
            if (tagFormatPart.tagConvention.padding > 0) {
              tagPart = tagPart.padStart(tagFormatPart.tagConvention.padding, "0");
            } else {
              tagPart = tagPart.slice(0, tagFormatPart.tagConvention.padding);
            }
          }
        } else {
          tagPart = "";
        }

        tag = tag.concat(
          tagPart,
          tagFormatPart.tagConvention.delimiter !== null
            ? tagFormatPart.tagConvention.delimiter
            : ""
        );
      });
    tag = tag.replaceAll("--", "").replace(/\-$/, "");

    if (tag.startsWith("-")) {
      tag = tag.slice(1);
    }

    return tag;
    }
  }

  get service() {
    if (this.line || this.equipment) {
      return (
        this.line?.service ||
        (this.secondaryService ? this.equipment?.secondaryService : this.equipment?.primaryService)
      );
    } else {
      return null;
    }
  }

  get minimumDesignTemperature() {
    const minimumDesignTemperatures = [];
    if (this.line && this.line.service.minimumDesignTemperature) {
      minimumDesignTemperatures.push(this.line.service.minimumDesignTemperature);
    }
    if (this.equipment) {
      const minimumDesignTemperature = this.secondaryService
        ? this.equipment.manualMinimumDesignTemperatureSecondary ??
          this.equipment.secondaryService?.minimumDesignTemperature
        : this.equipment.manualMinimumDesignTemperaturePrimary ??
          this.equipment.primaryService.minimumDesignTemperature;
      if (minimumDesignTemperature) {
        minimumDesignTemperatures.push(minimumDesignTemperature);
      }
    }

    if (minimumDesignTemperatures.length !== 0) {
      return Math.min(...minimumDesignTemperatures);
    } else {
      return null;
    }
  }
  get maximumDesignTemperature() {
    const maximumDesignTemperatures = [];
    if (this.line && this.line.service.maximumDesignTemperature) {
      maximumDesignTemperatures.push(this.line.service.maximumDesignTemperature);
    }
    if (this.equipment) {
      const maximumDesignTemperature = this.secondaryService
        ? this.equipment.manualMaximumDesignTemperatureSecondary ??
          this.equipment.secondaryService?.maximumDesignTemperature
        : this.equipment.manualMaximumDesignTemperaturePrimary ??
          this.equipment.primaryService.maximumDesignTemperature;
      if (maximumDesignTemperature) {
        maximumDesignTemperatures.push(maximumDesignTemperature);
      }
    }

    if (maximumDesignTemperatures.length !== 0) {
      return Math.max(...maximumDesignTemperatures);
    } else {
      return null;
    }
  }

  get minimumDesignPressure() {
    const minimumDesignPressures = [];
    if (this.line && this.line.service.minimumDesignPressure) {
      minimumDesignPressures.push(this.line.service.minimumDesignPressure);
    }
    if (this.equipment) {
      const minimumDesignPressure = this.secondaryService
        ? this.equipment.manualMinimumDesignPressureSecondary ??
          this.equipment.secondaryService?.minimumDesignPressure
        : this.equipment.manualMinimumDesignPressurePrimary ??
          this.equipment.primaryService.minimumDesignPressure;
      if (minimumDesignPressure) {
        minimumDesignPressures.push(minimumDesignPressure);
      }
    }

    if (minimumDesignPressures.length !== 0) {
      return Math.min(...minimumDesignPressures);
    } else {
      return null;
    }
  }
  get maximumDesignPressure() {
    const maximumDesignPressures = [];
    if (this.line && this.line.service.maximumDesignPressure) {
      maximumDesignPressures.push(this.line.service.maximumDesignPressure);
    }
    if (this.equipment) {
      const maximumDesignPressure = this.secondaryService
        ? this.equipment.manualMaximumDesignPressureSecondary ??
          this.equipment.secondaryService?.maximumDesignPressure
        : this.equipment.manualMaximumDesignPressurePrimary ??
          this.equipment.primaryService.maximumDesignPressure;
      if (maximumDesignPressure) {
        maximumDesignPressures.push(maximumDesignPressure);
      }
    }

    if (maximumDesignPressures.length !== 0) {
      return Math.max(...maximumDesignPressures);
    } else {
      return null;
    }
  }

  get phase() {
    if (this.service) {
      if (this.line) {
        return this.service.phase;
      } else if (this.equipment) {
        return this.secondaryService
          ? this.equipment.manualPhaseSecondary ?? this.equipment.secondaryService?.phase
          : this.equipment.manualPhasePrimary ?? this.equipment.primaryService.phase;
      }
    } else {
      return null;
    }
  }

  get pedPhase() {
    const pedPhases = [];
    if (this.line && this.line.service.pedPhase) {
      pedPhases.push(this.line.service.pedPhase);
    }
    if (this.equipment) {
      const pedPhase = this.secondaryService
        ? this.equipment.manualPedPhaseSecondary ?? this.equipment.secondaryService?.pedPhase
        : this.equipment.manualPedPhasePrimary ?? this.equipment.primaryService.pedPhase;
      if (pedPhase) {
        pedPhases.push(pedPhase);
      }
    }

    if (pedPhases.length !== 0) {
      if (pedPhases.length === 1) {
        return pedPhases[0];
      } else {
        return pedPhases.sort((a, b) => {
          return a.localeCompare(b, "en", { sensitivity: "base" });
        })[0];
      }
    } else {
      return null;
    }
  }

  get pedFluidGroup() {
    const pedFluidGroups = [];
    if (this.line && this.line.service.pedFluidGroup) {
      pedFluidGroups.push(this.line.service.pedFluidGroup);
    }
    if (this.equipment) {
      const pedFluidGroup = this.secondaryService
        ? this.equipment.secondaryService?.pedFluidGroup
        : this.equipment.primaryService.pedFluidGroup;
      if (pedFluidGroup) {
        pedFluidGroups.push(pedFluidGroup);
      }
    }

    if (pedFluidGroups.length !== 0) {
      return Math.min(...pedFluidGroups);
    } else {
      return null;
    }
  }

  get pipeSpec() {
    return this.service?.pipeSpec;
  }
  get medium() {
    return this.service?.medium;
  }
  get pedCategory() {
    if (
      this.service !== undefined &&
      this.pedFluidGroup &&
      this.connectionSize !== null &&
      this.pedPhase
    ) {
      const maxDesignPressure = this.maximumDesignPressure;
      const diameterDN =
        this.connectionSize.pipeSizeSystem == 2
          ? this.connectionSize.size
          : PipeSize.query().where("id", this.connectionSize.convertedPipeSizeId).withAll().first()
              ?.size;
      const pedPhase = this.pedPhase;
      const pedFluidGroup = this.pedFluidGroup;
      if (diameterDN !== undefined) {
        return pedCalculation.calculateLinePed(
          maxDesignPressure,
          diameterDN,
          pedPhase,
          pedFluidGroup
        );
      } else {
        return null;
      }
    }
    return null;
  }
  get atexLabel() {
    if (
      this.atexProductGroup &&
      this.atexProductCategory &&
      this.atexProtectionType &&
      this.atexExplosionGroup &&
      this.atexTemperatureClass &&
      this.atexEquipmentProtectionLevel
    ) {
      return `${this.atexProductGroup} ${this.atexProductCategory} ${this.atexProtectionType} ${this.atexExplosionGroup} ${this.atexTemperatureClass} ${this.atexEquipmentProtectionLevel}`;
    } else return "";
  }
  resolvePath = (object, path, defaultValue) =>
    path
      .split(/[\.\[\]\'\"]/)
      .filter((p) => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);


  exampleTag(instrumentTagConvention) {
    let tag = "";
    let tagPart;

    if (instrumentTagConvention === null) {
      return "";
    }

    if (instrumentTagConvention.length > 0) {
      for (const tagFormatPart of instrumentTagConvention) {
        tagPart =
          tagFormatPart.tagPart.modelName === Instrument.entity.slice(0, -1)
            ? this[tagFormatPart.tagPart.propertyPath]
            : this.resolvePath(this, tagFormatPart.tagPart.propertyPath);

        if (tagPart !== null && tagPart !== undefined) {
          tagPart = tagPart.toString();
          if (tagFormatPart.padding) {
            if (tagFormatPart.padding > 0) {
              tagPart = tagPart.padStart(tagFormatPart.padding, "0");
            } else {
              tagPart = tagPart.slice(0, tagFormatPart.padding);
            }
          }
          tag = tag.concat(
            tagPart,
            tagFormatPart.delimiter !== null ? tagFormatPart.delimiter : ""
          );
        }
      }

      return tag.replaceAll("--", "").replace(/\-$/, "");
    } else {
      return "";
    }
  }
}
