import { Model } from "@vuex-orm/core";

export default class PressureRating extends Model {
  static entity = "pressureRatings";
  static url = "/pressurerating";
  static friendlyName = "Pressure rating";

  static fields() {
    return {
      id: this.attr(0),
      // pressureRatingText: this.attr(null),
      value: this.attr(null),
      pipeSizeSystem: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }

  get pressureRatingText() {
    return this.pipeSizeSystem && this.value !== null
      ? this.pipeSizeSystem === "1"
        ? `${this.value}#`
        : `PN${this.value}`
      : null;
  }
}
