export default {
  calculateLinePed(maxDesignPressure, pipeSize, pedPhase, pedFluidGroup) {
    // Only applicable for gas and liquids that have a max design pressure >= 0,5 barg
    if (pedPhase === "Gas" && maxDesignPressure > 0.5) {
      const pressureMultipliedByLineSize = maxDesignPressure * pipeSize;
      if (pedFluidGroup === 1) {
        if (
          pipeSize > 350 ||
          (pipeSize > 100 && pressureMultipliedByLineSize > 3500)
        ) {
          return { id: 3, category: "Cat. III" };
        } else if (
          pipeSize > 100 ||
          (pipeSize > 25 && pressureMultipliedByLineSize > 1000)
        ) {
          return { id: 2, category: "Cat. II" };
        } else if (pipeSize > 25) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      } else {
        if (pipeSize > 250 && pressureMultipliedByLineSize > 5000) {
          return { id: 3, category: "Cat. III" };
        } else if (pipeSize > 100 && pressureMultipliedByLineSize > 3500) {
          return { id: 2, category: "Cat. II" };
        } else if (pipeSize > 32 && pressureMultipliedByLineSize > 1000) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      }
    } else if (pedPhase === "Liquid" && maxDesignPressure > 0.5) {
      const pressureMultipliedByLineSize = maxDesignPressure * pipeSize;
      if (pedFluidGroup === 1) {
        if (pipeSize > 25 && maxDesignPressure > 500) {
          return { id: 3, category: "Cat. III" };
        } else if (
          pipeSize > 25 &&
          maxDesignPressure > 10 &&
          pressureMultipliedByLineSize > 2000
        ) {
          return { id: 2, category: "Cat. II" };
        } else if (pipeSize > 25 && pressureMultipliedByLineSize > 2000) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      } else {
        if (pipeSize > 200 && maxDesignPressure > 500) {
          return { id: 2, category: "Cat. II" };
        } else if (
          pipeSize > 200 &&
          maxDesignPressure > 10 &&
          pressureMultipliedByLineSize > 5000
        ) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      }
    } else {
      return { id: 1, category: "N.A." };
    }
  },

  calculateEquipmentPed(maxDesignPressure, volume, pedPhase, pedFluidGroup) {
    // Only applicable for gas and liquids that have a max design pressure >= 0,5 barg
    if (pedPhase === "Gas" && maxDesignPressure > 0.5) {
      const pressureMultipliedByVolume = maxDesignPressure * volume;
      if (pedFluidGroup === 1) {
        if (
          (volume <= 1 && maxDesignPressure > 1000) ||
          (volume > 1 && pressureMultipliedByVolume > 1000)
        ) {
          return { id: 5, category: "Cat. IV" };
        } else if (
          (volume <= 1 && maxDesignPressure > 200) ||
          (volume > 1 && pressureMultipliedByVolume > 200)
        ) {
          return { id: 3, category: "Cat. III" };
        } else if (volume > 1 && pressureMultipliedByVolume > 50) {
          return { id: 2, category: "Cat. II" };
        } else if (volume > 1 && pressureMultipliedByVolume > 25) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      } else if (pedFluidGroup === 2) {
        if (
          (volume <= 1 && maxDesignPressure > 3000) ||
          (volume > 1 &&
            pressureMultipliedByVolume > 3000 &&
            maxDesignPressure > 4)
        ) {
          return { id: 5, category: "Cat. IV" };
        } else if (
          (volume <= 1 && maxDesignPressure > 1000) ||
          (volume > 1 && pressureMultipliedByVolume > 1000)
        ) {
          return { id: 3, category: "Cat. III" };
        } else if (volume > 1 && pressureMultipliedByVolume > 200) {
          return { id: 2, category: "Cat. II" };
        } else if (volume > 1 && pressureMultipliedByVolume > 50) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      }
    } else if (pedPhase === "Liquid" && maxDesignPressure > 0.5) {
      const pressureMultipliedByVolume = maxDesignPressure * volume;
      if (pedFluidGroup === 1) {
        if (volume > 1 && maxDesignPressure > 500) {
          return { id: 3, category: "Cat. III" };
        } else if (
          (volume <= 1 && maxDesignPressure > 500) ||
          (volume > 1 &&
            maxDesignPressure > 10 &&
            pressureMultipliedByVolume > 200)
        ) {
          return { id: 2, category: "Cat. II" };
        } else if (volume > 20 && pressureMultipliedByVolume > 200) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      } else if (pedFluidGroup === 2) {
        if (
          volume > 10 &&
          maxDesignPressure > 500 &&
          pressureMultipliedByVolume > 10000
        ) {
          return { id: 2, category: "Cat. II" };
        } else if (
          (volume <= 10 && maxDesignPressure > 1000) ||
          (pressureMultipliedByVolume > 10000 && maxDesignPressure > 10)
        ) {
          return { id: 1, category: "Cat. I" };
        } else {
          return { id: 4, category: "Art. 4, lid 3" };
        }
      }
    } else {
      return { id: 1, category: "N.A." };
    }
  },
};
