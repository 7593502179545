import { startCase } from "lodash";
import { upperFirst, lowerCase } from "lodash";

export default {
  //Undo/Redo
  setToolbarColor(vuetify) {
    return vuetify.theme.dark ? "#1E1E1E" : "#FFFFFF";
  },

  toStartCase(val) {
    return upperFirst(lowerCase(val));
  },
  //Snackbar
  showSnackbar(snack, color, text) {
    snack.show = true;
    snack.color = color;
    snack.text = text;
    return snack;
  },
  handleApiErrorResponse(err) {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      if (err.response.data.status == "error") {
        return err.response.data.message;
      } else {
        return err.response;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      return err.request;
    } else {
      return err;
    }
  },
};
