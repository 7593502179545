import { Model } from '@vuex-orm/core'

export default class Client extends Model {
  static entity = 'clients'
  static url = '/client'
  static friendlyName = "Client"

  static fields () {
    return {
      id: this.attr(0),
      name: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    }
  }
}