import { Model } from '@vuex-orm/core'
import Project from "./Project";
import TagConvention from './TagConvention';

export default class TagPart extends Model {
  static entity = 'tagParts'
  static url = '/tagPart'
  static friendlyName = "Tag part"

  static fields () {
    return {
      id: this.attr(0),
      objectType: this.attr(null),
      propertyPath: this.attr(null),
      propertyFriendlyName: this.attr(null),
      tagConventionId: this.attr(null),
      tagConvention: this.belongsTo(TagConvention, "tagConventionId"),
      modelName: this.attr(null),
      tableName: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),    
    }
  }
}