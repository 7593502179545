import { Model } from "@vuex-orm/core";
import Pid from "./Pid";
import Isometric from "./Isometric";
import Project from "./Project";
import Insulation from "./Insulation";
import Unit from "./Unit";
import Service from "./Service";
import ApplicableDirective from "./ApplicableDirective";
import EquipmentType from "./EquipmentType";
import pedCalculation from "../helpers/pedCalculation";
import TagConvention from "./TagConvention";
import SubProject from "./SubProject";

export default class Equipment extends Model {
  static entity = "equipments";
  static url = "/equipment";
  static friendlyName = "Equipment";

  static state() {
    return {
      icon: "mdi-pump",
      kvis: ["Y", "N", "N.A."],
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      number: this.attr(null),
      tagPrefix: this.attr(null),
      tagSuffix: this.attr(null),
      vicomaTag: this.attr(null),
      historyTag: this.attr(null),
      componentType: this.attr(null),
      manufacturer: this.attr(null),
      model: this.attr(null),
      designCode: this.attr(null),
      connectionType: this.attr(null),
      //flangeCode: this.attr(null),
      areaClassification: this.attr(null),
      manualMinimumDesignTemperaturePrimary: this.attr(null),
      manualMaximumDesignTemperaturePrimary: this.attr(null),
      manualMinimumDesignTemperatureSecondary: this.attr(null),
      manualMaximumDesignTemperatureSecondary: this.attr(null),
      manualMinimumDesignPressurePrimary: this.attr(null),
      manualMaximumDesignPressurePrimary: this.attr(null),
      manualMinimumDesignPressureSecondary: this.attr(null),
      manualMaximumDesignPressureSecondary: this.attr(null),
      manualPhasePrimary: this.attr(null),
      manualPedPhasePrimary: this.attr(null),
      manualPhaseSecondary: this.attr(null),
      manualPedPhaseSecondary: this.attr(null),
      minimumOperatingTemperaturePrimary: this.attr(null),
      normalOperatingTemperaturePrimary: this.attr(null),
      maximumOperatingTemperaturePrimary: this.attr(null),
      minimumOperatingTemperatureSecondary: this.attr(null),
      normalOperatingTemperatureSecondary: this.attr(null),
      maximumOperatingTemperatureSecondary: this.attr(null),
      atmBoilingPointPrimary: this.attr(null),
      atmBoilingPointSecondary: this.attr(null),
      minimumOperatingPressurePrimary: this.attr(null),
      normalOperatingPressurePrimary: this.attr(null),
      maximumOperatingPressurePrimary: this.attr(null),
      minimumOperatingPressureSecondary: this.attr(null),
      normalOperatingPressureSecondary: this.attr(null),
      maximumOperatingPressureSecondary: this.attr(null),
      vapourPressurePrimary: this.attr(null),
      vapourPressureSecondary: this.attr(null),
      pressureInlet: this.attr(null),
      pressureOutlet: this.attr(null),
      minimumDensityPrimary: this.attr(null),
      normalDensityPrimary: this.attr(null),
      maximumDensityPrimary: this.attr(null),
      minimumDensitySecondary: this.attr(null),
      normalDensitySecondary: this.attr(null),
      maximumDensitySecondary: this.attr(null),
      minimumViscosityPrimary: this.attr(null),
      normalViscosityPrimary: this.attr(null),
      maximumViscosityPrimary: this.attr(null),
      minimumViscositySecondary: this.attr(null),
      normalViscositySecondary: this.attr(null),
      maximumViscositySecondary: this.attr(null),
      atexFlammableMedium: this.attr(null),
      atexAreaClassification: this.attr(null),
      atexProductGroup: this.attr(null),
      atexProductCategory: this.attr(null),
      atexProtectionType: this.attr(null),
      atexExplosionGroup: this.attr(null),
      atexTemperatureClass: this.attr(null),
      atexEquipmentProtectionLevel: this.attr(null),
      volume: this.attr(null),
      capacity: this.attr(null),
      remarks: this.attr(null),
      notes: this.attr(null),
      state: this.attr(null),
      kvi: this.attr(null),
      description: this.attr(null),
      lastChangedBy: this.attr(null),
      revision: this.attr(null),
      attributes: this.attr(null),
      insulationLocation: this.attr(null),
      insulationThickness: this.attr(null),
      dimensions: this.attr(null),
      pumpDifferentialHead: this.attr(null),
      pumpPowerInstalled: this.attr(null),
      pumpPowerEstimated: this.attr(null),
      surfaceArea: this.attr(null),
      typeOfInternals: this.attr(null),
      materialHousing: this.attr(null),
      materialInternals: this.attr(null),
      weight: this.attr(null),
      noiseLevel: this.attr(null),
      deliveryTime: this.attr(null),
      requiredOnSiteDate: this.attr(null),
      estimatedOperatingHours: this.attr(null),
      registrationNumber: this.attr(null),
      pedRemarks: this.attr(null),
      // location: this.attr(null),
      projectScope: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      insulationId: this.attr(null),
      unitId: this.attr(null),
      primaryServiceId: this.attr(null),
      secondaryServiceId: this.attr(null),
      isometricId: this.attr(null),
      pidId: this.attr(null),
      insulation: this.belongsTo(Insulation, "insulationId"),
      equipmentType: this.belongsTo(EquipmentType, "equipmentTypeId"),
      projectId: this.attr(null),
      equipmentTypeId: this.attr(null),
      isometric: this.belongsTo(Isometric, "isometricId"),
      pid: this.belongsTo(Pid, "pidId"),
      unit: this.belongsTo(Unit, "unitId"),
      primaryService: this.belongsTo(Service, "primaryServiceId"),
      secondaryService: this.belongsTo(Service, "secondaryServiceId"),
      project: this.belongsTo(Project, "projectId"),
      subProjectId: this.attr(null),
      subProject: this.belongsTo(SubProject, "subProjectId"),
      validFrom: this.attr(null),
    };
  }

  get tag() {
    let tag = "";
    let tagParts = [];
    let tagPart;
    if (this.project) {
      tagParts = this.project.tagParts;
    } else {
      tagParts = Project.query().whereId(this.projectId).withAll().first()?.tagParts;
    }
    if (tagParts) {
      tagParts
      .filter((i) => i.objectType === "equipmentTagFormat")
      .sort((a, b) => a.tagConvention.index - b.tagConvention.index)
      .map((tagFormatPart) => {
        tagPart =
          tagFormatPart.modelName === Equipment.entity.slice(0, -1)
            ? this[tagFormatPart.propertyPath]
            : this.resolvePath(this, tagFormatPart.propertyPath);

        if (tagPart !== null && tagPart !== undefined) {
          tagPart = tagPart.toString();
          if (tagFormatPart.tagConvention.padding) {
            if (tagFormatPart.tagConvention.padding > 0) {
              tagPart = tagPart.padStart(tagFormatPart.tagConvention.padding, "0");
            } else {
              tagPart = tagPart.slice(0, tagFormatPart.tagConvention.padding);
            }
          }
        } else {
          tagPart = "";
        }

        tag = tag.concat(
          tagPart,
          tagFormatPart.tagConvention.delimiter !== null
            ? tagFormatPart.tagConvention.delimiter
            : ""
        );
      });
      tag = tag.replaceAll("--", "").replace(/\-$/, "");

      if (tag.startsWith("-")) {
        tag = tag.slice(1);
      }
  
      return tag;
    }
  }

  get primaryMinimumDesignTemperature() {
    return this.manualMinimumDesignTemperaturePrimary ?? this.primaryService.minimumDesignTemperature
  }
  get primaryMaximumDesignTemperature() {
    return this.manualMaximumDesignTemperaturePrimary ?? this.primaryService.maximumDesignTemperature
  }
  get secondaryMinimumDesignTemperature() {
    return this.manualMinimumDesignTemperatureSecondary ?? this.secondaryService?.minimumDesignTemperature
  }
  get secondaryMaximumDesignTemperature() {
    return this.manualMaximumDesignTemperatureSecondary ?? this.secondaryService?.maximumDesignTemperature
  }

  get primaryMinimumDesignPressure() {
    return this.manualMinimumDesignPressurePrimary ?? this.primaryService.minimumDesignPressure
  }
  get primaryMaximumDesignPressure() {
    return this.manualMaximumDesignPressurePrimary ?? this.primaryService.maximumDesignPressure
  }
  get secondaryMinimumDesignPressure() {
    return this.manualMinimumDesignPressureSecondary ?? this.secondaryService?.minimumDesignPressure
  }
  get secondaryMaximumDesignPressure() {
    return this.manualMaximumDesignPressureSecondary ?? this.secondaryService?.maximumDesignPressure
  }

  get primaryPedCategory() {
    return this.#pedCategory("primaryService");
  }

  get secondaryPedCategory() {
    return this.#pedCategory("secondaryService");
  }

  get applicableDirective() {
    const applicableDirectives = []
    if (this.equipmentType.applicableDirective) {
      applicableDirectives.push(this.equipmentType.applicableDirective)
    }
    if (this.atexAreaClassification) {
      applicableDirectives.push(ApplicableDirective.find(1))
    }
    return applicableDirectives
  }

  get atexLabel() {
    if (
      this.atexProductGroup &&
      this.atexProductCategory &&
      this.atexProtectionType &&
      this.atexExplosionGroup &&
      this.atexTemperatureClass &&
      this.atexEquipmentProtectionLevel
    ) {
      return `${this.atexProductGroup} ${this.atexProductCategory} ${this.atexProtectionType} ${this.atexExplosionGroup} ${this.atexTemperatureClass} ${this.atexEquipmentProtectionLevel}`;
    } else return "";
  }

  #pedCategory(service) {
    let manualMaximumDesignPressure = null;
    let manualPedPhase = null;

    if (service === "primaryService") {
      manualMaximumDesignPressure = this.manualMaximumDesignPressurePrimary;
      manualPedPhase = this.manualPedPhasePrimary;
    } else {
      manualMaximumDesignPressure = this.manualMaximumDesignPressureSecondary;
      manualPedPhase = this.manualPedPhaseSecondary;
    }

    if (
      this[service] &&
      (manualMaximumDesignPressure !== null || this[service].maximumDesignPressure !== null) &&
      this.volume !== null &&
      this[service].pedPhase !== null &&
      this[service].pedFluidGroup !== null
    ) {
      const maxDesignPressure = manualMaximumDesignPressure ?? this[service].maximumDesignPressure;
      const volume = this.volume;
      const pedPhase = manualPedPhase ?? this[service].pedPhase;
      const pedFluidGroup = this[service].pedFluidGroup;

      return pedCalculation.calculateEquipmentPed(
        maxDesignPressure,
        volume,
        pedPhase,
        pedFluidGroup
      );
    }
    return null;
  }

  resolvePath = (object, path, defaultValue) =>
    path
      .split(/[\.\[\]\'\"]/)
      .filter((p) => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  exampleTag(equipmentTagConvention) {
    let tag = "";
    let tagPart;

    if (equipmentTagConvention === null) {
      return "";
    }

    if (equipmentTagConvention.length > 0) {
      for (const tagFormatPart of equipmentTagConvention) {
        tagPart =
          tagFormatPart.tagPart.modelName === Equipment.entity.slice(0, -1)
            ? this[tagFormatPart.tagPart.propertyPath]
            : this.resolvePath(this, tagFormatPart.tagPart.propertyPath);

        if (tagPart !== null && tagPart !== undefined) {
          tagPart = tagPart.toString();
          if (tagFormatPart.padding) {
            if (tagFormatPart.padding > 0) {
              tagPart = tagPart.padStart(tagFormatPart.padding, "0");
            } else {
              tagPart = tagPart.slice(0, tagFormatPart.padding);
            }
          }
          tag = tag.concat(
            tagPart,
            tagFormatPart.delimiter !== null ? tagFormatPart.delimiter : ""
          );
        }
      }

      return tag.replaceAll("--", "").replace(/\-$/, "");
    } else {
      console.log(equipmentTagConvention);
      return "";
    }
  }
}
