import { Model } from '@vuex-orm/core'
import PipeSize from './PipeSize'
import Schedule from './Schedule'

export default class PipeWallThickness extends Model {
  static entity = 'pipeWallThicknesses'
  static url = "/pipewallthicknesse"
  static friendlyName = "Pipe wall thickness"

  static primaryKey = ['pipeSizeId', 'scheduleId']
  static fields () {
    return {
      thickness: this.attr(null),
      pipeSizeId: this.attr(null),
      pipeSize: this.belongsTo(PipeSize, "pipeSizeId"),
      scheduleId: this.attr(null),
      schedule: this.belongsTo(Schedule, "scheduleId"),
    }
  }
}