import { Model } from "@vuex-orm/core";

export default class ProcessFunction extends Model {
  static entity = "processFunctions";
  static url = "/processFunction"
  static friendlyName = "Process function"

  static fields() {
    return {
      id: this.attr(0),
      procFuncName: this.attr(null),
      spiProcFuncId: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }
}
