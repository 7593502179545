import auth from "./auth";
import PipeWallThickness from "../models/PipeWallThickness";
import User from "../models/User";
import PidLine from "../models/PidLine";
import TagConvention from "../models/TagConvention";
import General from "../models/General";
import graph from "../plugins/graph";
import * as Sentry from "@sentry/vue";
import { handleApiError } from "../plugins/errorHandler";

// const VED_BASE = "http://localhost:3000/api";
// const VED_BASE = "https://ved-api-dev.azurewebsites.net/api";
// const VED_BASE = "https://api-ved-prod.azurewebsites.net/api";

// const VED_SCOPES = ["https://ved-api-dev.azurewebsites.net/user_impersonation"];
// const VED_SCOPES = ["api://1b5b682c-ea46-4ae4-ac68-2c037f8dd380/user_impersonation"];

let accessToken;

export default {
  async getAll(model, projectId = 0) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;

    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await model.api().get(`${VED_BASE}${model.url}s?projectId=${projectId}`, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async get(model, id) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      console.log(`${VED_BASE}${model.url}/${id}`);
      const response = await model.api().get(`${VED_BASE}${model.url}/${id}`, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async create(model, item) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await model.api().post(`${VED_BASE}${model.url}/create`, item, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async bulkImport(model, items) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await model.api().post(`${VED_BASE}${model.url}/bulk-import`, items, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  filterObject(obj, callback) {
    return Object.fromEntries(Object.entries(obj).filter(([key, val]) => callback(val, key)));
  },

  async update(model, item) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    var startTime = performance.now();
    accessToken = await auth.acquireToken(VED_SCOPES);
    try {
      const store = User.store();
      const updatedItem = model.find(item.id);
      const asArray = Object.entries(updatedItem);
      const filtered = asArray.filter(
        ([key, value]) =>
          Array.isArray(value) ||
          value === null ||
          (typeof value !== "object" && typeof value !== "function")
      );
      const filteredItem = model.friendlyName === "Project" ? item : Object.fromEntries(filtered);
      const response = await model.api().put(`${VED_BASE}${model.url}/${item.id}`, filteredItem, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });

      var endTime = performance.now();
      return response.data;
    } catch (err) {      
      const id = item.id;
      this.get(model, id);
      throw handleApiError(err);
    }
  },
  async batchUpdate(model, items) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await model.api().put(`${VED_BASE}${model.url}/batchUpdate`, items, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async delete(model, id) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const headers = {
        authorization: `bearer ${accessToken}`,
        username: store.state.entities.users.user.username,
      };

      let response;
      if (Array.isArray(id)) {
        // If id is an array, send a DELETE request with the array in the request body
        response = await model.api().delete(`${VED_BASE}${model.url}`, {
          headers: headers,
          data: { ids: id },
        });
      } else {
        // If id is a single value, send a DELETE request with that single value in the URL
        response = await model.api().delete(`${VED_BASE}${model.url}/${id}`, {
          headers: headers,
        });
      }

      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  //`/api/pipewallthickness?scheduleId=${this.line.schedule.id}&pipeSizeId=${this.line.pipeSizeId}`

  async addToObject(model, parentObjectId, childObject) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);
    try {
      const store = User.store();
      const response = await model
        .api()
        .put(
          `${VED_BASE}${model.url}/add${childObject.constructor.url.slice(1)}/${parentObjectId}`,
          childObject,
          {
            headers: {
              authorization: `bearer ${accessToken}`,
              username: store.state.entities.users.user.username,
            },
          }
        );
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async removeFromObject(model, parentObjectId, childObject) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);
    try {
      const store = User.store();
      const response = await model
        .api()
        .put(
          `${VED_BASE}${model.url}/remove${childObject.constructor.url.slice(1)}/${parentObjectId}`,
          childObject,
          {
            headers: {
              authorization: `bearer ${accessToken}`,
              username: store.state.entities.users.user.username,
            },
          }
        );
      //this.get(model, lineId);
      if (childObject.constructor.friendlyName === "Pid") {
        PidLine.delete([childObject.id, parentObjectId]);
      } else if (childObject.constructor.friendlyName === "Tag part") {
        TagConvention.delete([childObject.id, parentObjectId]);
      }

      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async getPipeWallThickness(line) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await PipeWallThickness.api().get(
        `${VED_BASE}${PipeWallThickness.url}?scheduleId=${line.schedule.id}&pipeSizeId=${line.pipeSizeId}`,
        {
          headers: {
            authorization: `bearer ${accessToken}`,
            username: store.state.entities.users.user.username,
          },
        }
      );
      return response.data;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async getHistory(model, id) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await fetch(`${VED_BASE}${model.url}/history/${id}`, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });

      return await response.json();
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async getProjectLogbook(projectId) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await fetch(`${VED_BASE}/project/projectLogbook/${projectId}`, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });

      return await response.json();
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async getProjectUsers(projectId) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);
    let response;

    try {
      const store = User.store();

      response = await fetch(`${VED_BASE}/project/users/${projectId}`, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });

      const projectMembers = await response.json();
      const promises = projectMembers.map((projectMember) => graph.searchUsers(projectMember.user));

      Promise.all(promises)
        .then((responses) => {
          // Process the responses
          responses.forEach((response) => {
            // Handle each response here
            if (response !== null && response.value.length > 0) {
              const user = response.value[0];
              let userToAdd = {};
              userToAdd.id = user.id;
              userToAdd.name = user.displayName;
              userToAdd.jobTitle = user.jobTitle;
              userToAdd.username = user.userPrincipalName;
              userToAdd.graphPhoto = null;
              graph
                .getUserPhoto(user.userPrincipalName)
                .then((userPhoto) => {
                  // Handle the nested response here
                  if (userPhoto) {
                    userToAdd.graphPhoto = userPhoto;
                  }
                  User.insertOrUpdate({
                    data: userToAdd,
                  });
                })
                .catch((nestedError) => {
                  // Handle any errors from the nested request
                  console.log(nestedError);
                });
            }
          });
        })
        .catch((error) => {
          // Handle any errors that occurred during the requests
          console.log(error);
        });
      return projectMembers;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async getProjectUserStats(projectId, startDate = null, endDate = null) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);
    let response;

    try {
      const store = User.store();

      if (startDate && endDate) {
        response = await fetch(
          `${VED_BASE}/project/userStats/${projectId}?startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              authorization: `bearer ${accessToken}`,
              username: store.state.entities.users.user.username,
            },
          }
        );
      } else {
        response = await fetch(`${VED_BASE}/project/userStats/${projectId}`, {
          headers: {
            authorization: `bearer ${accessToken}`,
            username: store.state.entities.users.user.username,
          },
        });
      }

      const projectUserStats = await response.json();

      return projectUserStats;
    } catch (err) {
      throw handleApiError(err);
    }
  },

  async createProjectBackup(projectId) {
    const VED_SCOPES = [General.VED_SCOPES];
    const VED_BASE = General.VED_BASE;
    accessToken = await auth.acquireToken(VED_SCOPES);

    try {
      const store = User.store();
      const response = await fetch(`${VED_BASE}/backup/${projectId}`, {
        headers: {
          authorization: `bearer ${accessToken}`,
          username: store.state.entities.users.user.username,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // Extract filename from Content-Disposition header if present
      const contentDisposition = response.headers.get("Content-Disposition");
      console.log(contentDisposition);
      let filename = "backup_project.json.gz";
      if (contentDisposition) {
        const matches = /filename="([^"]*)"/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1];
        }
      }

      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {      
      alert("Failed to download backup");
      throw handleApiError(err);
    }
  },

  //
  // Accessor for access token, only included for demo purposes
  //
  getAccessToken() {
    return accessToken;
  },
};
